// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo[data-v-479f5318] {
  width: 32px;
}
.logo-span.expanded[data-v-479f5318] {
  width: auto;
}
.logo-span.expanded[data-v-479f5318] {
  display: flex;
  gap: 5px;
  align-items: center;
  height: 35px;
}
.logo-text[data-v-479f5318] {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.5px;
  width: 100px;
}
.sidebar[data-v-479f5318] {
  background-color: #3c3c3c;
  width: 44px;
  z-index: 100000;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
}
.sidebar.expanded[data-v-479f5318] {
  width: 183px;
}
.collapse-bar.expanded[data-v-479f5318] {
  left: 161.2px;
}
.collapse-bar[data-v-479f5318] {
  width: 31px;
  height: 31px;
  position: fixed;
  z-index: 100000;
  top: 60px;
  left: 22px;
  display: flex;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.15));
  transition-property: left;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
  cursor: pointer;
}
.policies[data-v-479f5318] {
  color: #b5b5b5;
  font-size: 12px;
  width: 100%;
  padding: 15px 6px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.5px;
  overflow: hidden;
  white-space: nowrap;
}
.policies .blue[data-v-479f5318] {
  color: #4195ff;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
