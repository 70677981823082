<template>
    <OnClickOutside @trigger="openDrop = false">
        <div class="drop-multiple-header" @click="openDropContainer" :class="{
            active: openDrop,
            rotate: rotated
        }" :style="{
            width: `${width}`,
            height: `${height}`,
        }">
            <div class="drop-title" :style="{borderColor}">
                <template v-if="slots">
                    <span v-if="openDrop">
                        <slot name="filter"></slot>
                    </span>
                    <span v-else-if="selectedValues.length === list.length || !selectedValues.length">
                        <slot name="all"></slot>
                    </span>
                    <span v-else>{{ selectedValues.length }} <slot name="selected"></slot></span>
                    <Icon name="arrow_down" />
                </template>
                <template v-else>
                    <span>
                        <slot></slot>
                    </span>
                    <Icon name="arrow_down" />
                </template>
            </div>
            <Transition>

                <div v-if="openDrop" @click.stop class="drop-options" :style="{
                    width: `${selectWidth}`,
                    top: !this.rotated ? `${height}` : '',
                    bottom: this.rotated ? `${height}` : '',
                }">
                    <div class="select-input" v-show="searchable">
                        <input ref="search" type="text" :placeholder="$t('button.search')" class="search-bar"
                            @input="handleSearch($event.target.value)" />
                    </div>
                    <div class="select-input" :class="{ custom: allOption && !searchable }" v-show="allOption && computedItems.length">
                        <div class="drop-item all-items" @click="clickAllItems(this.selectedValues)" :class="{active: allSelectedValuesIncluded()}">
                            <div class="select-svg" v-if="allSelectedValuesIncluded()">
                                <Icon name="checkbox_active" />
                            </div>
                            <div class="select-svg" v-else>
                                <Icon name="checkbox" />
                            </div>
                            <div v-t="this.search ? 'misc.selectAllMatching' : 'misc.selectAll'"></div>
                        </div>
                    </div>
                    <div class="drop-list">
                        <div class="no-results" v-if="!computedItems.length">{{ $t('misc.noResults') }}</div>
                        <UseVirtualList :list="computedItems" :options="{ itemHeight: 35 }"
                            :height="computedItems.length > 6 ? '230px' : 'auto'" :key="refresh">
                            <template #default="props">
                                <div :title="props.data.name" class="drop-item" @click="selectOption(props.data)">
                                    <div class="select-svg" v-if="selectedValues.includes(props.data.value)">
                                        <Icon name="checkbox_active" />
                                    </div>
                                    <div class="select-svg" v-else>
                                        <Icon name="checkbox" />
                                    </div>
                                    <span class="prop-name" :class="{ active: selectedValues.includes(props.data.value) }">{{ props.data.name }}</span>
                                </div>
                            </template>
                        </UseVirtualList>
                    </div>
                    <div class="drop-actions" v-if="!noFooter">
                        <div class="drop-buttons">
                            <button type="button" @click="selectedValues = []">{{ $t('button.clearAll') }}</button>
                            <button type="button" @click="saveList(this.selectedValues)">{{ $t('button.save') }}</button>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>

    </OnClickOutside>
</template>

<script>
import { OnClickOutside, UseVirtualList } from "@vueuse/components";

export default {
    components: { OnClickOutside, UseVirtualList },
    emits: ["update:selected", "optionChanged"],
    props: {
        searchable: {
            type: Boolean,
            required: false,
            default: true
        },
        allOption: {
            type: Boolean,
            required: false,
            default: true
        },
        borderColor: {
            default: "#dbdbdb",
            required: false,
        },
        width: {
            required: false,
            default: "160px"
        },
        height: {
            default: "35px",
            required: false
        },
        selectWidth: {
            default: 'inherit',
            required: false
        },
        slots: {
            required: false,
            default: false,
        },
        selected: {
            type: Array,
            default: () => [],
        },
        list: {
            default: []
        },
        noFooter: {
            default: false,
            required: false,
        },
        autoRotate: {
            required: false,
            default: false
        },
        lazyCaller: {
            required: false,
            default: true
        }
    },
    data() {
        return {
            refresh: false,
            search: '',
            selectedValues: [],
            openDrop: false,
            rotated: false,
            values: [],
        }
    },
    methods: {
        async handleSearch(value) {
            if(this.lazyCaller)
                await this.$lazyCaller('search', value, 150);
            else 
                this.search = value;
        },
        allSelectedValuesIncluded() {
            const selectedValuesSet = new Set(this.selectedValues);
            return this.computedItems.every(item => selectedValuesSet.has(item.value));
        },
        clickAllItems(values = this.selectedValues) {
            if (this.searchable) this.$refs.search.focus();

            // Check if all items are already selected
            if (this.allSelectedValuesIncluded()) {
                this.selectedValues = values.filter(value => !this.computedItems.some(item => item.value === value));

                if (this.noFooter){
                    this.$emit("update:selected", this.selectedValues)
                    this.$emit("optionChanged", this.selectedValues)
                }

                return;
            }

            this.computedItems.forEach((o) => {
                if (!values.includes(o.value)) this.selectedValues.push(o.value);
            });

            if (this.noFooter){
                this.$emit("update:selected", this.selectedValues)
                this.$emit("optionChanged", this.selectedValues)
            }
        },
        selectOption(item) {
            if (this.searchable) this.$refs.search.focus();

            if (!this.selectedValues.includes(item.value)) this.selectedValues.push(item.value);
            else this.selectedValues.splice(this.selectedValues.indexOf(item.value), 1);

            if (this.noFooter){
                this.$emit("update:selected", this.selectedValues)
                this.$emit("optionChanged", this.selectedValues)
            }
        },
        saveList(values = this.selectedValues) {
            this.$emit("update:selected", values)
            this.$emit("optionChanged", values)
            this.openDrop = false;
        },
        openDropContainer() {
            if (this.autoRotate) {
                const componentRect = event.target.getBoundingClientRect();
                const yPos = componentRect.top / window.innerHeight
                this.rotated = yPos > 0.5;
            }
            this.openDrop = !this.openDrop;

            if (this.openDrop && this.searchable)
                this.$nextTick(() => {
                    this.$refs.search.focus();
                })

            // Put the selected items on top of the this.list
            let list = JSON.parse(JSON.stringify(this.list)) || [];
            const selected = list.filter(x => this.selected.includes(x.value));
            if (selected.length) {
                list = list.filter(x => !selected.includes(x));
                list.unshift(...selected);
            }
            this.values = list;

        },
    },
    watch: {
        search() {
            this.refresh = !this.refresh;
        },
        openDrop() {
            if(this.selected)
            this.selectedValues = [...this.selected];
            this.search = '';
        },
        selected: {
            handler(val) {
                if (this.selectedValues !== val) {
                    this.selectedValues = [...this.selected];
                }
            },
            deep: true
        },
    },
    computed: {
        getItemName() {
            return this.list.find(x => x.value === this.selected)?.name || this.selected;
        },
        computedItems() {
            return this.values.filter((item) => {
                return item.name.toLowerCase().includes(this.search.toLowerCase());
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.drop-multiple-header {
    width: auto;
    height: 35px;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
    cursor: pointer;
    gap: 5px;

    &:hover .drop-title {
        background-color: #ededed;
    }

    &.rotate {
        .drop-options {
            flex-direction: column-reverse;
            margin-bottom: 3px;
        }

        .select-input {
            border-radius: 5px 5px 0 0;
        }
    }

    &.active {
        box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);
        z-index: 10000;

        &.rotate {
            .drop-title {
                border-radius: 0 0 5px 5px;
            }

            .drop-options {
                border-radius: 5px 5px 0 0;
            }
        }

        .drop-title {
            background-color: #ededed;
            border-color: white;
        }
    }

    .drop-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 0 12px;
        border-radius: 5px;
        border: solid 2px #dbdbdb;
        transition: all 0.2s ease-in-out;
        background-color: #fff;
        height: 100%;

        svg {
            width: 20px;
        }

        span {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }

    .drop-options {
        display: flex;
        flex-direction: column;
        z-index: 10000;
        position: absolute;
        left: 0;
        max-height: 350px;
        background: #fff;
        box-shadow: 0 1.5px 5.9px 0.7px rgba(0, 0, 0, 0.17);
        margin-top: 3px;
        border-radius: 5px;
        overflow-y: hidden;

        .drop-list {
            margin-top: 1px;
            min-height: 35px;
            max-height: 230px;
            overflow-y: auto;

            .no-results {
                height: 35px;
                display: flex;
                align-items: center;
                padding-left: 15px;
                font-size: 14px;
                color: #5f5f5f;
                font-weight: normal;
            }
        }
    }

    .drop-item {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 14px;
        color: #5f5f5f;
        font-weight: normal;
        overflow-x: hidden;
        cursor: pointer;
        gap: 5px;
        line-height: 1.5;

        &.active {
            font-weight: 600;
        }

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            max-width: 100%;
            vertical-align: top;
        }

        &:hover {
            background-color: #f4f4f4;
        }
    }

    .select-input {
        border: 2px solid #ededed;
        height: 35px;
        margin: 2px 2px;
        border-radius: 5px 5px 0 0;

        &.custom {
            border-radius: 5px !important;
            border-top: 2px solid #ededed !important;
            margin: 2px 2px !important;
            height: 38px;
        }

        &:nth-child(2) {
            border-top: unset;
            margin: -2px 2px;
            border-radius: 0 0 5px 5px;
        }

        & .drop-item {
            padding: 0 5.5px;
        }
    }

    input {
        width: 100%;
        outline: none;
        border: 4px solid transparent;
        background: url(@/assets/svg/Icon_search.svg) no-repeat scroll 5px 3px;
        padding-left: 30px;
    }

}

.prop-name.active {
    font-weight: 600;
}

.select-svg {
    display: flex;
    align-items: center;
}

.drop-actions {
    border-top: 2px solid #ededed;
}

.drop-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 8.5px 15px;
    gap: 15px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    color: #8d8d8d;

    & button:nth-child(2) {
        font-weight: 600;
        color: #4195ff;
    }
}


.v-enter-active,
.v-leave-active {
    transition: opacity 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

:deep(.drop-multiple-header .drop-title svg) {
    transition: transform 0.2s ease-in-out;
}

:deep(.drop-multiple-header.active) {
    .drop-title svg {
        transform: rotate(180deg)
    }
}
</style>
<style lang="scss">

</style>