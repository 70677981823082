<template>
    <OnClickOutside @trigger="openDrop = false">
        <div class="drop-header unselectable" @click="openDropContainer" :class="{
            active: openDrop,
            rotate: rotated,
        }" :style="{
            minWidth: expand ? `100%`: width,
            width,
            height,
        }">
            <div class="drop-title" :class="{invalid}" :style="{borderColor}">
                <template v-if="!slots">
                    <span class="placeholder-text" v-if="placeholder && !getItemName">{{ placeholder }}</span>
                    <span v-else>{{ getItemName }}</span>
                </template>
                <template v-else>
                    <span><slot></slot></span>
                </template>
                <div class="up-down d-flex" width="25px">
                    <Icon name="arrow_down" />
                </div>
            </div>
            <Transition>
                <div v-if="openDrop" @click.stop class="drop-options" :class="{ inversed }" :style="{
                    width: selectWidth,
                    minWidth: expand ? `100%`: selectWidth,
                    top: !this.rotated ? `${height}` : '',
                    bottom: this.rotated ? `${height}` : '',
                }">
                    <div class="select-input" v-if="searchable">
                        <input ref="search" type="text" :placeholder="$t('button.search')" class="search-bar"
                            @input="handleSearch($event.target.value)" />
                    </div>
                    <div class="drop-list">
                        <div class="no-results" v-if="!computedItems.length">{{ $t('misc.noResults') }}</div>
                        <UseVirtualList :list="computedItems" :options="{ itemHeight: 35, overscan: 5 }"
                            :height="computedItems.length > 6 ? '200px' : 'auto'" :key="refresh">
                            <template #default="props">
                                <div 
                                    :title="props.data.name" class="drop-item" 
                                    :class="{active: keepAsArray ? selected.name === props.data.name : selected === props.data.value, 'disable opacity-25': props.data.disabled }"
                                    @click="selectOption(props.data)"
                                >
                                    <div class="checkbox">
                                        <Icon name="checkmark" v-if="keepAsArray ? selected.name === props.data.name : selected === props.data.value"  />
                                    </div>
                                    <span>{{ props.data.name }}</span>
                                </div>
                            </template>
                        </UseVirtualList>
                    </div>
                </div>
            </Transition>

        </div>
    </OnClickOutside>
</template>

<script>
import { OnClickOutside, UseVirtualList } from "@vueuse/components";

export default {
    components: { OnClickOutside, UseVirtualList },
    emits: ["optionChanged", "update:selected"],
    props: {
        placeholder: {
            required: false,
        },
        borderColor: {
            default: "#dbdbdb",
            required: false,
        },
        searchable: {
            type: Boolean,
            required: false,
            default: true
        },
        keepAsArray: {
            default: false,
            required: false,
        },
        id: {
            required: false,
        },
        expand: {
            default: false,
            required: false,
        },
        selectWidth: {
            default: 'inherit',
            required: false
        },
        width: {
            default: '200px',
            required: false
        },
        height: {
            default: '35px',
            required: false
        },
        slots: {
            required: false,
            default: false,
        },
        autoRotate: {
            default: false,
            required: false,
        },
        selected: {
            default: '',
        },
        list: {
            default: []
        },
        lazyCaller: {
            type: Boolean,
            required: false,
            default: true
        },
        invalid: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            refresh: false,
            search: '',
            openDrop: false,
            rotated: false,
            inversed: false,
            values: [],
        }
    },
    methods: {
        async handleSearch(value) {
            if(this.lazyCaller)
                await this.$lazyCaller('search', value, 150);
            else
                this.search = value;
        },
        selectOption(item) {
            if (this.id)
                this.$emit('optionChanged', { result: item, id: this.id });
            else
                this.$emit('optionChanged', item);

            if (this.keepAsArray)
                this.$emit('update:selected', item)
            else
                this.$emit('update:selected', item.value)

            this.openDrop = false;
        },
        openDropContainer(event) {
            if (this.autoRotate) {
                const componentRect = event.target.getBoundingClientRect();
                const yPos = componentRect.top / window.innerHeight
                const xPos = (componentRect.left + componentRect.width) / window.innerWidth

                this.rotated = yPos > 0.5;
                this.inversed = xPos > 0.7;
            }

            this.openDrop = !this.openDrop;
            if (this.openDrop && this.searchable)
                this.$nextTick(() => {
                    this.$refs.search.focus();
                })

            let list = JSON.parse(JSON.stringify(this.list)) || [];

            let selected = {};
            if(this.keepAsArray) selected = list.find(x => x.name === this.selected.name);
            else selected = list.find(x => x.value === this.selected);
            
            if (selected){ 
                list.splice(list.indexOf(selected), 1);
                list.unshift(selected);
            }
            this.values = list;
        },
    },
    watch: {
        search() {
            this.refresh = !this.refresh;
        },
        openDrop(open) {
            if (open) this.search = ''
        }
    },
    computed: {
        getItemName() {
            if (this.keepAsArray) return this.selected.name
            return this.list.find(x => x.value === this.selected)?.name || this.selected;
        },
        computedItems() {
            return this.values.filter((item) => {
                return item.name.toLowerCase().includes(this.search.toLowerCase());
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.drop-header {
    width: auto;
    height: 35px;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
    cursor: pointer;

    &:hover .drop-title {
        background-color: #ededed;
    }

    &.rotate {
        .drop-options {
            flex-direction: column-reverse;
        }
    }

    &.active {
        box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);
        z-index: 1000;

        .drop-title {
            background-color: #ededed;
            border-color: white !important;
        }
        .drop-item{
            font-weight: 600;
        }
    }

    .drop-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 0 12px;
        border-radius: 5px;
        border: solid 2px #dbdbdb;
        background-color: #fff;
        transition: all 0.2s ease-in-out;
        height: 100%;
        gap: 10px;

        span.placeholder-text {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.5px;
            color: #b5b5b5;
        }

        span {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }

    .drop-options {
        display: flex;
        flex-direction: column;
        box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);
        z-index: 100;
        position: absolute;
        left: 0;
        max-height: 250px;
        background: #fff;
        border-radius: 5px;
        margin-top: 3px;
        margin-bottom: 3px;
        overflow-y: hidden;

        &.inversed {
            left: unset;
            right: 0;
        }

        .drop-list {
            min-height: 35px;
            max-height: 200px;
            overflow-y: auto;

            .no-results {
                height: 35px;
                display: flex;
                align-items: center;
                padding-left: 15px;
                font-size: 14px;
                color: #5f5f5f;
                font-weight: normal;
            }

            .drop-item {
                height: 35px;
                display: flex;
                align-items: center;
                padding: 0 10px;
                font-size: 14px;
                color: #5f5f5f;
                font-weight: normal;
                overflow-x: hidden;
                cursor: pointer;
                line-height: 1.5;

                &.active {
                    font-weight: 600;
                }

                .checkbox {
                    width: 20px;
                    min-width: 20px;
                    display: flex;
                }

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    max-width: 100%;
                    vertical-align: top;
                }

                &:hover {
                    background-color: #f4f4f4;
                }
            }
        }
    }

    .select-input {
        border: 2px solid #ededed;
        margin: 2px;
        border-radius: 5px;
        height: 38px;
    }

    input {
        width: 100%;
        height: 35px;
        outline: none;
        border: 2px solid transparent;
        background: url(@/assets/svg/Icon_search.svg) no-repeat scroll 7px 6px;
        padding-left: 30px;
    }

}

.invalid {
    border-color: #dc3545 !important;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}


:deep(.drop-header .drop-title .up-down) {
    transition: transform 0.2s ease-in-out;
}

:deep(.drop-header.active .drop-title .up-down) {
        transform: rotate(180deg)
}
</style>
<style lang="scss"></style>