<template>
  <div
    :style="[
      submenu ? 'background-color: #ededed' : '',
      expanded ? 'box-shadow: 0 0 10px 0 rgba(33, 33, 33, 0.15)' : '',
    ]"
  >
    <router-link
      :to="{
        name: link ? link : '',
      }"
    >
      <div
        @click="appStore.sidebar ? (expanded = !expanded) : submenu ? expandSubmenu() : ''"
        class="button mb-1"
        :class="{
          active: $route.path.includes(link) || (submenu && expanded) || activeLink,
          expanded: appStore.sidebar || (expanded && submenu),
        }"
        style="z-index: 10"
      >
        <div
          :class="submenu ? 'icon-button-with-submenu' : 'icon-button'"
          v-tooltip="{
            placement: 'right',
            theme: 'nonius-tooltip',
            content: title,
            disabled: appStore.sidebar,
          }"
          ref="tooltipBtn"
        >
          <template v-if="submenu">
            <div class="d-flex align-items-center">
              <img v-if="icon" :src="require(`@/assets/svg/${icon}.svg`)" alt="" />
              <div
                :class="{ active: $route.path.includes(link) || expanded || activeLink }"
                class="menu-text"
                v-show="appStore.sidebar || expanded"
              >
                {{ title }}
              </div>
            </div>
            <img
              :src="require(`@/assets/svg/Icon_arrow_down.svg`)"
              alt=""
              class="menu-arrow"
              v-show="appStore.sidebar || expanded"
              :style="expanded ? 'transform: rotateY(180deg)' : ''"
            />
          </template>

          <template v-else>
            <img v-if="icon" :src="require(`@/assets/svg/${icon}.svg`)" alt="" />
            <div :class="{ active: $route.path.includes(link) }" class="menu-text" v-show="appStore.sidebar">
              {{ title }}
            </div>
          </template>
        </div>
      </div>
      <Transition name="slide-up">
        <div v-if="submenu && expanded">
          <template v-for="item in submenu" :key="item.title">
            <router-link
              :to="{
                name: item.link,
              }"
            >
              <div class="button mb-1 active" :class="{ expanded: appStore.sidebar || expanded }">
                <div
                  class="icon-button"
                  v-tooltip="{
                    placement: 'right',
                    theme: 'nonius-tooltip',
                    content: item.title,
                    disabled: appStore.sidebar,
                  }"
                  ref="tooltipBtn"
                >
                  <span class="circle-container">
                    <span v-if="$route.name === item.link" class="circle">
                      <span class="inner-circle"></span>
                    </span>
                  </span>
                  <div class="menu-text active" v-show="appStore.sidebar || expanded">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </div>
      </Transition>
    </router-link>
  </div>
</template>
<script>
  import { useAppStore } from '@/store';

  export default {
    setup() {
      const appStore = useAppStore();
      return { appStore };
    },
    props: {
      title: String,
      icon: String,
      link: String,
      index: Number,
      submenu: Array,
    },
    data() {
      return {
        expanded: false,
        activeLink: false,
      };
    },
    watch: {
      '$route.path': {
        handler() {
          //make submenu icon active if one if his sublinks are open
          if (this.submenu) {
            const foundItem = this.submenu.filter(item => this.$route.path.includes(item.link))[0];
            this.activeLink = !!foundItem;
          }
          //close submenu and submenu expansion on link click
          if (this.expanded) this.expanded = false
        },
        immediate: true,
      },
      'appStore.sidebar'(val){
        if(!val){
          this.expanded = false;
        }
      },
    },
    methods: {
      expandSubmenu() {
        this.expanded = !this.expanded;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .button {
    background-color: #484848;
    width: 44px;
    height: 40px;
    display: flex;
    justify-content: center;
    place-items: center;
    transition-property: width;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.2s;
    overflow: hidden;
  }
  .icon-button {
    height: 38px;
    stroke: #b3b3b3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-button-with-submenu {
    height: 38px;
    stroke: #b3b3b3;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button.expanded {
    width: 183px;
    justify-content: flex-start;
    padding: 0 12px;
  }
  .button.expanded .icon-button {
    width: auto;
    text-transform: capitalize;
  }

  .button.expanded .icon-button-with-submenu {
    width: 100%;
    text-transform: capitalize;
  }

  .menu-arrow {
    transform: rotateX(180deg);
    filter: invert(79%) sepia(9%) saturate(11%) hue-rotate(314deg) brightness(91%) contrast(92%);
  }

  .menu-text {
    text-wrap: wrap;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    width: 122px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: #b5b5b5;
    padding-left: 8px;
  }
  .menu-text.active {
    color: #484848;
  }
  a,
  a:hover,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: none;
  }

  a:hover .button {
    background-color: #595959;
  }

  a .button.active {
    background-color: #F4F4F4;
    color: black;
  }
  .button.active img {
    filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
  }

  .circle-container {
    width: 20px;
    display: flex;
    align-items: center;
    justify-items: center;

    .circle {
      margin: auto;
      border-radius: 50%;
      background-color: #4195ff;
      height: 10px;
      width: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .inner-circle {
        border-radius: 50%;
        background-color: #fff;
        height: 6px;
        width: 6px;
      }
    }
  }

  .slide-up-enter-active {
    transition: all 0.1s ease-out;
  }

  .slide-up-leave-active {
    transition: all 0.1s ease-in;
  }

  .slide-up-enter-to,
  .slide-up-leave-from {
    overflow: hidden;
    max-height: 40px;
  }

  .slide-up-enter-from,
  .slide-up-leave-to {
    overflow: hidden;
    max-height: 0;
  }
</style>
