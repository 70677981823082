// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/svg/Icon_search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-multiple-header[data-v-578b262a] {
  width: auto;
  height: 35px;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: relative;
  cursor: pointer;
  gap: 5px;
}
.drop-multiple-header:hover .drop-title[data-v-578b262a] {
  background-color: #ededed;
}
.drop-multiple-header.rotate .drop-options[data-v-578b262a] {
  flex-direction: column-reverse;
  margin-bottom: 3px;
}
.drop-multiple-header.rotate .select-input[data-v-578b262a] {
  border-radius: 5px 5px 0 0;
}
.drop-multiple-header.active[data-v-578b262a] {
  box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);
  z-index: 10000;
}
.drop-multiple-header.active.rotate .drop-title[data-v-578b262a] {
  border-radius: 0 0 5px 5px;
}
.drop-multiple-header.active.rotate .drop-options[data-v-578b262a] {
  border-radius: 5px 5px 0 0;
}
.drop-multiple-header.active .drop-title[data-v-578b262a] {
  background-color: #ededed;
  border-color: white;
}
.drop-multiple-header .drop-title[data-v-578b262a] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 12px;
  border-radius: 5px;
  border: solid 2px #dbdbdb;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  height: 100%;
}
.drop-multiple-header .drop-title svg[data-v-578b262a] {
  width: 20px;
}
.drop-multiple-header .drop-title span[data-v-578b262a] {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.drop-multiple-header .drop-options[data-v-578b262a] {
  display: flex;
  flex-direction: column;
  z-index: 10000;
  position: absolute;
  left: 0;
  max-height: 350px;
  background: #fff;
  box-shadow: 0 1.5px 5.9px 0.7px rgba(0, 0, 0, 0.17);
  margin-top: 3px;
  border-radius: 5px;
  overflow-y: hidden;
}
.drop-multiple-header .drop-options .drop-list[data-v-578b262a] {
  margin-top: 1px;
  min-height: 35px;
  max-height: 230px;
  overflow-y: auto;
}
.drop-multiple-header .drop-options .drop-list .no-results[data-v-578b262a] {
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 14px;
  color: #5f5f5f;
  font-weight: normal;
}
.drop-multiple-header .drop-item[data-v-578b262a] {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #5f5f5f;
  font-weight: normal;
  overflow-x: hidden;
  cursor: pointer;
  gap: 5px;
  line-height: 1.5;
}
.drop-multiple-header .drop-item.active[data-v-578b262a] {
  font-weight: 600;
}
.drop-multiple-header .drop-item span[data-v-578b262a] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.drop-multiple-header .drop-item[data-v-578b262a]:hover {
  background-color: #f4f4f4;
}
.drop-multiple-header .select-input[data-v-578b262a] {
  border: 2px solid #ededed;
  height: 35px;
  margin: 2px 2px;
  border-radius: 5px 5px 0 0;
}
.drop-multiple-header .select-input.custom[data-v-578b262a] {
  border-radius: 5px !important;
  border-top: 2px solid #ededed !important;
  margin: 2px 2px !important;
  height: 38px;
}
.drop-multiple-header .select-input[data-v-578b262a]:nth-child(2) {
  border-top: unset;
  margin: -2px 2px;
  border-radius: 0 0 5px 5px;
}
.drop-multiple-header .select-input .drop-item[data-v-578b262a] {
  padding: 0 5.5px;
}
.drop-multiple-header input[data-v-578b262a] {
  width: 100%;
  outline: none;
  border: 4px solid transparent;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat scroll 5px 3px;
  padding-left: 30px;
}
.prop-name.active[data-v-578b262a] {
  font-weight: 600;
}
.select-svg[data-v-578b262a] {
  display: flex;
  align-items: center;
}
.drop-actions[data-v-578b262a] {
  border-top: 2px solid #ededed;
}
.drop-buttons[data-v-578b262a] {
  display: flex;
  justify-content: flex-end;
  padding: 8.5px 15px;
  gap: 15px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  color: #8d8d8d;
}
.drop-buttons button[data-v-578b262a]:nth-child(2) {
  font-weight: 600;
  color: #4195ff;
}
.v-enter-active[data-v-578b262a],
.v-leave-active[data-v-578b262a] {
  transition: opacity 0.2s ease-in-out;
}
.v-enter-from[data-v-578b262a],
.v-leave-to[data-v-578b262a] {
  opacity: 0;
}
[data-v-578b262a] .drop-multiple-header .drop-title svg {
  transition: transform 0.2s ease-in-out;
}
[data-v-578b262a] .drop-multiple-header.active .drop-title svg {
  transform: rotate(180deg);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
